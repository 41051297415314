import React from 'react';
import { useSpring, animated } from 'react-spring';
import './App.css';


const App = () => {
    const [showLogo, setShowLogo] = React.useState(false);
    const [showTitle, setShowTitle] = React.useState(false);
    const [showText, setShowText] = React.useState(false);
    const [showTwitter, setShowTwitter] = React.useState(false);
    const [showDiscord, setShowDiscord] = React.useState(false);
    const [showGithub, setShowGithub] = React.useState(false);
    const [showEmail, setShowEmail] = React.useState(false);
    const [show7, setShow7] = React.useState(false);
    const [show8, setShow8] = React.useState(false);
    const [show9, setShow9] = React.useState(false);
    const [showBuy, setShowBuy] = React.useState(false);


    React.useEffect(() => {
        setTimeout(() => setShowLogo(true), 500);
        setTimeout(() => setShowTitle(true), 1500);
        setTimeout(() => setShowText(true), 2000);
        setTimeout(() => setShowTwitter(true), 2500);
        setTimeout(() => setShowDiscord(true), 3000);
        setTimeout(() => setShowGithub(true), 3500);
        setTimeout(() => setShowEmail(true), 4000);
        setTimeout(() => setShow7(true), 4500);
        setTimeout(() => setShow8(true), 5000);
        setTimeout(() => setShow9(true), 5500);
        setTimeout(() => setShowBuy(true), 6000);
    }, []);

    const logoProps = useSpring({
        opacity: showLogo ? 1 : 0,
        from: { opacity: 0 },
        config: { duration: 500 },
    });

    const titleProps = useSpring({
        opacity: showTitle ? 1 : 0,
        from: { opacity: 0 },
        config: { duration: 500 },
    });

    const textProps = useSpring({
        opacity: showText ? 1 : 0,
        from: { opacity: 0 },
        config: { duration: 500 },
    });

    const twitterProps = useSpring({
        opacity: showTwitter ? 1 : 0,
        from: { opacity: 0 },
        config: { duration: 500 },
    });

    const discordProps = useSpring({
        opacity: showDiscord ? 1 : 0,
        from: { opacity: 0 },
        config: { duration: 500 },
    });

    const githubProps = useSpring({
        opacity: showGithub ? 1 : 0,
        from: { opacity: 0 },
        config: { duration: 500 },
    });

    const props7 = useSpring({
        opacity: show7? 1 : 0,
        from: { opacity: 0 },
        config: { duration: 500 },
    });

    const props8 = useSpring({
        opacity: show8? 1 : 0,
        from: { opacity: 0 },
        config: { duration: 500 },
    });

    const props9 = useSpring({
        opacity: show9? 1 : 0,
        from: { opacity: 0 },
        config: { duration: 500 },
    });

    const propsBuy = useSpring({
        opacity: showBuy? 1 : 0,
        from: { opacity: 0 },
        config: { duration: 500 },
    });

    const emailProps = useSpring({
        opacity: showEmail? 1 : 0,
        from: { opacity: 0 },
        config: { duration: 500 },
    });

    return (
        <div className="app-container">
            <div className="background"></div>
            <animated.div style={logoProps}>
                <img
                    className="logo"
                    src="yogurt.png"
                    alt="Logo"
                />
            </animated.div>
            <animated.div style={titleProps}>
                <h1 className="title">CA : SOON !</h1>
            </animated.div>
            <div className="social-icons">
                <a href="https://twitter.com/yogurtsol" target="_blank" rel="noopener noreferrer">
                    <animated.img
                        className="icon twitter"
                        style={twitterProps}
                        src="twitter.png"
                        alt="Twitter"
                    />
                </a>
                <a href="https://t.me/yogurtsol" target="_blank" rel="noopener noreferrer">
                    <animated.img
                        className="icon telegram"
                        style={discordProps}
                        src="telegram.png"
                        alt="Discord"
                    />
                </a>
                <a href="https://www.dextools.io/app/en/pairs" target="_blank" rel="noopener noreferrer">
                    <animated.img
                        className="icon dextools"
                        style={githubProps}
                        src="dextools.svg"
                        alt="Dextools"
                    />
                </a>
                <a href="https://coinmarketcap.com/" target="_blank" rel="noopener noreferrer">
                    <animated.img
                        className="icon coinmarketcap"
                        style={props7}
                        src="cmc.png"
                        alt="coinmarkatcap"
                    />
                </a>
                <a href="https://explorer.solana.com/" target="_blank" rel="noopener noreferrer">
                    <animated.img
                        className="icon solana"
                        style={props8}
                        src="solana.png"
                        alt="solana"
                    />
                </a>
                <a href="https://www.coingecko.com/" target="_blank" rel="noopener noreferrer">
                    <animated.img
                        className="icon coingecko"
                        style={props9}
                        src="cg.png"
                        alt="coingecko"
                    />
                </a>
            </div>
            <div className="social-icons">
                <a href="https://raydium.io/swap" target="_blank" rel="noopener noreferrer">
                    <animated.img
                        className="icon2 raydium"
                        style={propsBuy}
                        src="buy.png"
                        alt="raydium"
                    />
                </a>
            </div>
        </div>
    );
};

export default App;
